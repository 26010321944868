import { GetServerSideProps } from 'next';
import { Fragment } from 'react';

import { DefaultPage, RenderBlock } from '@klappir/container/graphcms';
import { getPage } from '@klappir/data/graphcms';
import { DataGraphcms } from '@klappir/types';
import { SEO } from '@klappir/ui/core';

export function Page({ content }: { content: DataGraphcms.QueryResultPage }) {
  return (
    <DefaultPage localizations={content.localizations}>
      {content?.seo && (
        <SEO canonical="https://www.klappir.com" {...content.seo} />
      )}
      {content?.blocks?.map((block, i) => (
        <Fragment key={i}>
          <RenderBlock key={i} {...block} />
        </Fragment>
      ))}
    </DefaultPage>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const nodeEnv = process?.env?.NODE_ENV;
  const vercelEnv = process?.env?.VERCEL_ENV;
  const stage =
    nodeEnv === 'development' || vercelEnv !== 'production'
      ? 'DRAFT'
      : 'PUBLISHED';
  const content = await getPage('home', context.locale, stage);
  return {
    props: { content },
  };
};

export default Page;
